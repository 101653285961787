import React, { useEffect, useState } from 'react';
import Image from 'gatsby-image';
import { graphql, useStaticQuery, Link } from 'gatsby';
import IconHamburger from '../../../assets/svg/iconHamburger.svg';
import IconCross from '../../../assets/svg/iconCross.svg';
import NavMenu from './NavMenu';
import { isMobile } from '../../utils';
import { Button } from '../Button';

const Header = ({}) => {
  const { site, headerLogo } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          navMenu {
            title
            href
            items {
              description
              href
              title
            }
          }
        }
      }
      headerLogo: file(relativePath: { eq: "pwc-logo.png" }) {
        publicURL
      }
    }
  `);

  const mobile = isMobile();
  const [mobileMenuShow, setMobileMenuShow] = useState(false);

  const toggleMenu = () => {
    setMobileMenuShow(!mobileMenuShow);
  };

  useEffect(() => {
    setMobileMenuShow(false);
  }, [mobile]);

  return (
    <header className="header">
      <Link className="header__logo-link" to="/">
        <img
          className="header__logo"
          aria-label="PwC logo"
          src={headerLogo.publicURL}
          alt="PwC | Workforce Experience"
        /> { mobile ? ('') : (<span>Workforce Experience</span>)}
      </Link>
      <NavMenu
        className={`header__nav${mobileMenuShow ? ' shown' : ''}`}
        items={site.siteMetadata.navMenu}
      />

      <Button
        className="header__button"
        variant={'header'}
        theme={'dark'}
        href={'/demo'}
      >
        Request demo
      </Button>

      {mobileMenuShow ? (
        <IconCross
          onClick={toggleMenu}
          className="header__menu-icon header__menu-icon--close"
        />
      ) : (
        <IconHamburger
          onClick={toggleMenu}
          className="header__menu-icon header__menu-icon--open"
        />
      )}
    </header>
  );
};

export default Header;
