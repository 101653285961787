import React from 'react';
import Image from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import SocialIcons, { SocialItemsData } from '../SocialIcons';
import Link from '../Link';

const FooterLegal = () => {
  const year = new Date().getFullYear() 
  return (
    <div className="footer__legal">
      <p>
        The information on this website is intended to outline PwC's general
        product offering and may vary including for particular clients or
        configurations of the product. It is intended for informational purposes
        only and should not be relied upon in making a purchase decision, nor
        incorporated into any contract. It is not a commitment to deliver any
        material, code, or functionality. The development, release, and timing
        of any features or functionality for PwC's products remains at PwC's
        discretion.
        <br /><br />

        © {year} PwC. All rights reserved. PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity. Please see <Link className="footer__white" href="https://www.pwc.com/structure">www.pwc.com/structure</Link> for further details.</p>
    </div>
  );
};

const FooterLink = ({ title, href }) => {
  return (
    <Link className="footer__link" href={href}>
      {title}
    </Link>
  );
};
const FooterLinkGroup = ({ title, slug, links }) => {
  return (
    <div className={`footer__group footer-group--${slug}`}>
      <p className={`footer__group-header`}>{title}</p>
      {links.map(({ title, url }, index) => (
        <FooterLink title={title} href={url} key={index} />
      ))}
    </div>
  );
};

const Footer = (props) => {
  const data = useStaticQuery(graphql`
    {
      footerLogo: file(relativePath: { eq: "pwc-logo-white.png" }) {
        publicURL
      }
    }
  `);
  const { footerLogo } = data;

  const SolutionsLinks = [
    {
      title: 'Tech-enabled change management',
      url: '/solutions/tech-enabled-change-management',
    },
    {
      title: 'Transforming employee experience',
      url: '/solutions/transforming-employee-experience',
    },
    {
      title: 'Workforce analytics',
      url: '/solutions/workforce-analytics',
    }
  ];

  const LearnMoreLinks = [
    {
      title: 'Resources',
      url: '/resources',
    },
    {
      title: 'Saratoga Workforce Index',
      url: '/saratoga-workforce-index-hr-metrics',
    },
    {
      title: 'Blog',
      url: '/blog',
    },
    {
      title: 'Contact us',
      url: '/contact',
    },
    {
      title: 'PwC Products',
      url: 'https://www.pwc.com/us/en/products.html',
    },
  ];

  const ProductsLinks = [
    {
      title: 'Saratoga',
      url: '/products/saratoga',
    },
    {
      title: 'ProEdge',
      url: '/products/proedge',
    },
    {
      title: 'Next Level HR',
      url: '/products/next-level-hr',
    },
    {
      title: 'Listen Platform',
      url: '/products/listen-platform',
    },
    {
      title: 'Workforce Architect',
      url: '/products/workforce-architect',
    }
  ];

  const InfoLinks = [
    {
      title: 'Privacy',
      url: 'https://www.pwc.com/us/en/site/privacy.html',
    },
    {
      title: 'Legal',
      url: 'https://www.pwc.com/us/en/site/legal.html',
    },
    {
      title: 'Terms and conditions',
      url: 'https://www.pwc.com/us/en/site/terms-and-conditions.html',
    },
    {
      title: 'Cookie information',
      url: 'https://www.pwc.com/us/en/site-information.html',
    },
    {
      title: 'Do not sell my info',
      url: 'https://www.pwc.com/us/en/site/notice-of-right-to-opt-out.html',
    },
  ];

  const FooterLinksData = [
    { title: 'Products', slug: 'products', links: ProductsLinks },
    { title: 'Solutions', slug: 'solutions', links: SolutionsLinks },
    { title: 'Learn more', slug: 'learn-more', links: LearnMoreLinks },
    { title: 'Info', slug: 'info', links: InfoLinks },
  ];

  return (
    <footer className="footer">
      <div className="footer__inner">
        <div className="footer__group footer__group--logo">
          <div className="footer__logo">
            <a href="/"><img className="footer__logo-image" src={footerLogo.publicURL} /></a>
          </div>
          <FooterLink
            href="https://www.pwc.com"
            title={'pwc.com'}
          />
          <SocialIcons
            items={SocialItemsData.filter((item) =>
              ['twitter', 'linkedin'].includes(item.slug),
            )}
            theme="dark"
          />
        </div>
        {/* Links */}
        {FooterLinksData.map((links, index) => {
          return (
            <div
              className={`footer__group footer__group--${links.slug}`}
              key={index}
            >
              <FooterLinkGroup {...links} key={index} />
            </div>
          );
        })}
        
        <div className="footer__group footer__group--legal">
          <FooterLegal />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
